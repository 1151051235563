import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/carHome'
  },
  {
    path: '/carHome',
    name: 'CarHome',
    component: () => import('../views/car-home'),
    meta: {
      name: '首页'
    }
  },
  {
    path: '/carAbout',
    name: 'CarAbout',
    component: () => import('../views/car-about'),
    meta: {
      name: '关于我们'
    }
  },
  {
    path: '/carProduct',
    name: 'CarProduct',
    component: () => import('../views/car-product'),
    meta: {
      name: '产品中心'
    }
  },
  {
    path: '/carProduct/detail',
    name: 'CarProductDetail',
    component: () => import('../views/car-product/detail.vue'),
    meta: {
      name: '产品中心'
    }
  },
  {
    path: '/carUse',
    name: 'Caruse',
    component: () => import('../views/car-use'),
    meta: {
      name: '应用领域'
    }
  },
  {
    path: '/carUse/detail',
    name: 'CaruseDetail',
    component: () => import('../views/car-use/detail.vue'),
    meta: {
      name: '应用领域'
    }
  },
  {
    path: '/carCapacity',
    name: 'Carcapacity',
    component: () => import('../views/car-capacity'),
    meta: {
      name: '公司能力'
    }
  },
  {
    path: '/carCapacity/detail',
    name: 'CarcapacityDetail',
    component: () => import('../views/car-capacity/detail.vue'),
    meta: {
      name: '公司能力'
    }
  },
  {
    path: '/carRelation',
    name: 'Carrelation',
    component: () => import('../views/car-relation'),
    meta: {
      name: '联系我们'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

const resolveOriginal = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return resolveOriginal.call(this, location).catch((err) => err)
}

export default router
