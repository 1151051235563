import request from '@/utils/request'

//首页接口
export function getHome(data) {
  return request({
    url: 'Index/index',
    method: 'get',
    params: data
  })
}
