<template>
  <div id="app">
    <div class="pcLayout">
      <!-- 头部区域开始 -->
      <div class="pcLayout-header">
        <div class="pcLayout-header-main">
          <img
            class="pcLayout-header-main-logo"
            :src="logoInform.logo"
            alt=""
          />
          <div class="pcLayout-header-main-menu">
            <div
              class="pcLayout-header-main-menu-item"
              :class="{ activeMenu: activeIndex === item.name }"
              v-for="item in logoInform.menu"
              :key="item.id"
              @click="menuClick(item)"
            >
              {{ item.name }}
              <div class="englishName">{{ item.nickname }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 头部区域结束 -->

      <!-- 内容区域开始 -->
      <div class="pcLayout-content">
        <router-view></router-view>
      </div>
      <!-- 内容区域结束 -->

      <div class="relation" v-if="aboutUs">
      <div class="relation-head">
        <img src="@/assets/img/relation.png" alt="" />
      </div>
      <div class="relation-content">
        <div class="relation-content-left">
          <div class="relation-content-left-info">
            电话：{{ contactUs.plat_phone }}
          </div>
          <div class="relation-content-left-info">
            邮箱：{{ contactUs.plat_email }}
          </div>
          <div class="relation-content-left-info">
            微信：{{ contactUs.plat_chuanzhen }}
          </div>
          <div class="relation-content-left-info">
            地址：{{ contactUs.plat_address }}
          </div>
        </div>
        <div class="relation-content-right">
          <el-form ref="form" :model="queryInfo">
            <div class="form-item">
              <div class="form-item-left">
                <div class="form-item-left-name">您的姓名</div>
                <div>YOUR NAME</div>
              </div>
              <el-form-item>
                <el-input v-model="queryInfo.name"></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <div class="form-item-left">
                <div class="form-item-left-name">联系电话</div>
                <div>YOUR PHONE</div>
              </div>
              <el-form-item>
                <el-input v-model="queryInfo.phone"></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <div class="form-item-left">
                <div class="form-item-left-name">其他备注</div>
                <div>OTHER NOTE</div>
              </div>
              <el-form-item>
                <el-input type="textarea" v-model="queryInfo.remark"></el-input>
              </el-form-item>
            </div>

            <el-form-item class="submit-button">
              <el-button type="primary" @click="onSubmit"
                >提交/SUBMIT</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
      <!-- 底部区域 -->
      <div class="pcLayout-bottom">
        <div class="pcLayout-bottom-main">
          <img src="@/assets/img/police.png" alt="" />
          <div
            class="pcLayout-bottom-main-item"
            v-for="(item, index) in bottomInform"
            :key="index"
            :class="{ activeBottom: index === 2 }"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSlideItem, sendMessage } from '@/api/common'
import { getLogoAndMenu, getbottomInform } from '@/api/common'
import { getHome } from '@/api/home'
export default {
  data() {
    return {
      activeIndex: '首页',
      logoInform: {},
      bottomInform: [],
      contactUs:{},
      queryInfo: {
        name: '',
        phone: '',
        remark: ''
      },
      aboutUs:true,
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        this.logoInform?.menu?.forEach((item) => {
          if (item.name === newVal.meta.name) {
            this.activeIndex = item.name
          }
        })
        if(newVal.fullPath=='/carRelation'){
          this.aboutUs=false
        }else{
          this.aboutUs=true
        }
        console.log(newVal)
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.searchLogoAndMenu()
    this.searchBottomInform()
    this.searchHome()
  },
  methods: {
    async searchHome() {
      const res = await getHome()
      if (res.code === 1) {
        this.whyList = res.data.select_us
        this.product = res.data.product
        this.selectReason = res.data.select_reason
        this.classicCase = res.data.company_case
        this.coreCompetence = res.data.emergency

        this.coreCompetence.forEach((item) => {
          item.title = item.title.replace(/\s*/g, '')
        })
        this.emergencyImg = this.coreCompetence.map((item) => {
          return item.img
        })
        this.aboutUs = res.data.about_us[0]
        this.contactUs = res.data.connect_us
      }
    },
    async onSubmit() {
      const res = await sendMessage(this.queryInfo)
      if (res.code === 1) {
        this.queryInfo = {
          name: '',
          phone: '',
          remark: ''
        }
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async searchLogoAndMenu() {
      const res = await getLogoAndMenu()
      if (res.code === 1) {
        this.logoInform = res.data
        const url = [
          '/carHome',
          '/carAbout',
          '/carProduct',
          '/carCapacity',
          '/carUse',
          '/carRelation',
        ]
        this.logoInform.menu.forEach((item, index) => {
          item.path = url[index]
          if (item.name === this.$route.meta.name) {
            this.activeIndex = item.name
          }
        })
      }
    },
    menuClick(item) {
      this.$router.push({
        path: item.path
      })
    },
    async searchBottomInform() {
      const res = await getbottomInform()
      if (res.code === 1) {
        this.bottomInform = res.data.beian
      }
    }
  }
}
</script>

<style lang="less" scoped>
 .relation {
    padding-bottom: 190px;
    width: 100%;
    .relation-head {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 0 40px;
    }
    .relation-content {
      display: flex;
      justify-content: center;
      .relation-content-left {
        margin-right: 100px;
        .relation-content-left-info {
          margin-bottom: 35px;
        }
      }
      .relation-content-right {
        .form-item {
          display: flex;
          align-items: center;
          .form-item-left {
            width: 200px;
            text-align: right;
            font-size: 10px;
            margin: 0 22px 22px 0;
            .form-item-left-name {
              font-size: 16px;
              padding-bottom: 5px;
            }
          }
        }
        .submit-button {
          text-align: right;
        }
      }
    }
  }
.pcLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  .pcLayout-header {
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    width: 100%;
    justify-content: center;
    background: #282828;
    color: #fff;
    .pcLayout-header-main {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pcLayout-header-main-logo {
        width: 180px;
        height: 40px;
      }
      .pcLayout-header-main-menu {
        display: flex;
        justify-content: space-between;
        .pcLayout-header-main-menu-item {
          font-size: 20px;
          text-align: center;
          cursor: pointer;
          padding: 10px 20px;
          .englishName {
            font-size: 10px;
            padding-top: 4px;
          }
        }
        .activeMenu {
          background: #ffd700;
          color: #000;
        }
      }
    }
  }
  .pcLayout-content {
    flex: 1;
    width: 100%;
  }
  .pcLayout-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #282828;
    width: 100%;
    border-bottom: 9px solid #ffd700;
    .pcLayout-bottom-main {
      padding: 20px 0;
      width: 1200px;
      display: flex;
      align-items: center;
      .pcLayout-bottom-main-item {
        padding: 0 20px;
        font-size: 14px;
        color: #808080;
        border-right: 1px solid #808080;
      }
      .activeBottom {
        border-right: none;
      }
    }
  }
}
</style>
