import axios from "axios";

const request = axios.create({
  baseURL: "https://tire.996sh.com/api",
  timeout: 600000,
});

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return error;
  }
);

export default request;
